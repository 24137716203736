input:focus, input:hover, button:hover, button:active{
  outline: none;
}
a, a:hover{
  text-decoration:none;
  color: #000;
}
.o1 {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 10px solid #FFC947;
  margin-left: 0px;
  margin-right: -50px;
  display: inline-block;
  animation: thecodes1 1.8s infinite alternate;
}
.o2 {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 10px solid #0A1931;
  margin: 0 auto;
  display: inline-block;
  animation: thecodes2 1s infinite alternate;
}
.o3 {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 10px solid #0A1931;
  margin-left:-50px;
  display: inline-block;
  animation: thecodes3 3s infinite alternate;
}
@keyframes thecodes1{
  30%{margin-right:-50px;margin-left:0;}
  100%{margin-right:-10px;margin-left:0;}
}
@keyframes thecodes2{
  30%{margin-left:0;}
  100%{margin-right:0;}
}
@keyframes thecodes3{
  30%{margin-left:-50px;}
  100%{margin-left:-10px;}
}
.header-earlier{
  margin-bottom: 90px;
}
.header-div{
  background: linear-gradient(270deg, hsla(0, 0%, 97%, 1) 0%, hsla(0, 0%, 99%, 0) 100%);
  background: -moz-linear-gradient(270deg, hsla(0, 0%, 97%, 1) 0%, hsla(0, 0%, 99%, 0) 100%);
  background: -webkit-linear-gradient(270deg, hsla(0, 0%, 97%, 1) 0%, hsla(0, 0%, 99%, 0) 100%);
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  padding: 10px 25px;
}
.header{
  width: 100%;
  padding: 7px;
  border-radius: 6px;
  background: #5755b9;
  margin: 0 auto;
  height: 60px;
}
.header-left{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.network-using{
  height: 46px;
  width: 46px;
  border-radius: 50%;
  background: #f7f7f7;
  margin-left: 0;
  border: 3px solid green;
  display: none;
}
.network-using img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.thecodes-header{
  height: 46px;
  width: auto;
  margin: 0 5px;
}
.header-menu-show{
  height: 46px;
  width: 46px;
  border-radius:6px;
  position: absolute;
  right: 31px;
  cursor: pointer;
  display: none;
  transition: 700ms;
}
.header-menu-show:hover{
  background: rgba(0,0,0,0.15);
}
.header-menu-show .bi{
  font-size: 30px;
  color: white;
}
.header-right{
  display: flex;
  flex-wrap: nowrap;
  text-align: right;
  position: absolute;
  right: 31px;
  transition: 1s;
  margin-top: 3px;
  width: 70%;
}
.header-right .header-menu{
  color: white;
  font-size: 15px;
  padding: 5px;
  transition: 700ms;
  margin: 0 auto;
  height: 35px;
  -ms-transform: translateY(12%);
  transform: translateY(12%);
  margin: 0 5px;
  cursor: pointer;
}
.header-right .header-menu:hover{
  background: rgba(0,0,0,0.15);
  border-radius: 6px;
}
.header-right .header-menu .header-link{
  color: white;
}
.header-search{
  display: none;
}
.search-header{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 7px;
}
.search-header input{
  height: 100%;
  width: 100%;
  border: 0;
  background: transparent;
  color: #fff;
}
.search-header button{
  height: 100%;
  width: 50px;
  background: transparent;
  border: 0;
  color: #fff;
}
.search-header input::-webkit-input-placeholder {
  color: white;
  opacity: 0.5;
}
.summary-div{
  padding: 0 25px;
}
.summary{
  padding: 6px;
  width: 100%;
  border-radius: 6px;
  background: #fff;
  border: 1.5px solid grey;
  transition: 700ms;
  margin-top: 15px;
}
.summary:hover{
  border-color: white;
}
.title-div{
  width: 100%;
  padding: 6px;
  transition: 700ms;
}
.hr-right{
  border-right: 1px solid grey;
}
.title-div hr{
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
}
.title-div .title-icon{
  font-size: 40px;
  display: inline-block;
  transition: 700ms;
}
.title-div .title{
  display: inline-block;
  margin-left: 10px;
  margin-top: 5px;
  position: absolute;
  opacity: 0.7;
  font-size: 13px;
  transition: 700ms;
  cursor: default;
}
.title-div .title-value{
  display: inline-block;
  margin-left: 10px;
  margin-top: 20px;
  position: absolute;
  font-size: 25px;
  font-weight: bold;
  transition: 700ms;
  cursor: default;
}
.title-div:hover > .title-value{
  color: #5755b9;
  margin-top: 0px;
}
.title-div:hover > .title-icon{
  color: rgb(57, 116, 172);
}
.title-div:hover > .title{
  margin-top: 35px;
}
.hr-mobile{
  display: none;
}
.hide-mobile{
  display: block;
}
.hide-mobile.inline{
  display: inline-block;
}
.content{
  width: 100%;
  border-radius: 5px;
  background: white;
}
.text-hash{
  white-space: nowrap; 
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000;
}
.image-validator {
  height: 19px;
  width: auto;
  border-radius: 5px;
  margin-right: 10px;
}
.content-table-link{
  color:#174bc4;
}
.content-table-link:hover, .content-table-link:focus, .content-table-link:active{
  color: #174bc4;
}
.content-table-text{
  font-size: 15px;
  word-wrap: break-word;
}
.content-table-title{
  font-weight: bold;
  color: rgb(64, 63, 141);
}
.overflow-custom::-webkit-scrollbar {
  width: 4px;
}
.overflow-custom::-webkit-scrollbar-track {
  background: #fff; 
}
.overflow-custom::-webkit-scrollbar-thumb {
  background: rgba(23, 75, 196, 0.5);
  border-radius:100px;
}
.uptime-container{
  display: flex;
  flex-wrap: wrap;
}
.uptime-light{
  width: 18px;
  margin: 1.5px;
  text-align: center;
  height: 18px;
  font-size: 30px;
  border-radius:2px;
}
.pie-chart{
  margin-top:auto;
  margin-bottom:auto;
  margin-right: auto;
  margin-left: auto;
}
.dropdown-earlier{
  margin-top: 0px;
}
.dropdown-button::after{
  display: none;
}
.dropdown-button-token{
  background: transparent;
  padding: 0;
  border: 0;
  color:black;
  font-size: 15px;
}
.dropdown-button-token:hover, .dropdown-button-token:focus{
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
.dropdown-menu-token{
  width: inherit !important;
  max-width: 100%;
  z-index: 1;
}
.dropdown-item{
  transition: 700ms;
}
.switch {
  display: inline-block;
  height: 19px;
  position: absolute;
  width: 45px;
  margin-top: 10px;
}
.switch .checkbox {
  display:none;
}
.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}
.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 11px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 11px;
}
.checkbox:checked + .slider {
  background-color: #66bb6a;
}
.checkbox:checked + .slider:before {
  transform: translateX(26px);
}
.slider.round {
  border-radius: 30px;
}
.slider.round:before {
  border-radius: 50%;
}
.tab-div{
  width: 100%;
  background:transparent;
  display: inline-block;
}
.tab-button{
  padding: 15px;
  width: 50%;
  background: white;
  font-weight: bold;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-style: solid;
  border-color: #174bc4;
}
.connected-div{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.connected-line {
  height: 3px;
  background: #18bc9c;
  width: 100%;
  margin-top: 21px;
  border-radius: 20px;
}
.connected-line:after {
  float: right;
}
.connected-div .connected-text{
  width: 160px;
  padding: 6px;
  height: 45px;
  text-align: center;
  border-radius: 50px;
  background: #174bc4;
  font-size: 15px;
  color: white;
  border: 5px solid #f7f7f7;
}

.footer-container{
  padding: 10px 25px;
  background-color: #fff;
  margin-top: 20px;
}
.footer-social{
  text-align: center;
  padding-top: 16px;
}
.footer-copyright{
  text-align: right;
  padding-top: 16px;
}
.footer-social a, .footer-copyright p{
  color: #0A1931;
  font-size: 15px;
  transition: 700ms;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}

@media screen and (max-width: 424px) {
  .pie-chart{
    margin-top: 0;
    width: 100px;
    height: auto;
  }
  .footer-social{
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .header-earlier{
    margin-bottom: 135px;
  }
  .header-menu-show{
    display: block;
  }
  .header-right{
    display: block;
    width: 60%;
    right: 24px;
    transition: 700ms;
    border-radius: 6px;
    overflow: hidden;
    background: #5755b9;
    margin-top: 60px;
    height: 0;
    text-align: left;
    z-index: 100;
  }
  .header-menu-show:hover + .header-right{
    height: 113px;
  }
  .search-header{
    display: none;
  }
  .header-right:hover{
    height: 113px;
  }
  .header-search {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 15px;
  }
  .header-search input{
    width: 100%;
    border-radius: 6px 0 0 6px;
    border: 2px solid #5755b9;
    padding: 6px;
  }
  .header-search button{
    width: 50px;
    border-radius: 0 6px 6px 0;
    border: 2px solid #5755b9;
    padding: 6px;
    background: #5755b9;
    color: white;
  }
  .hr-right{
    border: 0;
  }
  .hr-mobile{
    display: block;
  }
  .hide-mobile{
    display: none;
  }
  .hide-mobile.inline{
    display: none;
  }
  .pie-chart{
    margin-top: 0;
    width: 100px;
    height: auto;
  }
  .footer-social{
    text-align: left;
  }
}